<template>
  <div class="top-games">
    <div class="h2"><i></i> <nuxt-link to="/top-games">ТОП игр</nuxt-link></div>
    <div class="h3">Выбор игроков</div>
    <div class="wrapper">
      <div class="items" :style="`width:${items.length * 210}px;`">
        <nuxt-link
          v-for="(item, i) in items"
          :key="`top-${item.id}-${i}`"
          :to="`/games/${postUrl(item.id, item.name)}`"
          class="item"
        >
          <div class="poster">
            <!-- <img :src="`https://media.rawg.io/media/games/511/5118aff5091cb3efec399c808f8c598f.jpg`" :alt="item.name"> -->
            <!-- <img :src="`/static_files/uploads/${item.id}.jpg`" :alt="item.name"> -->
            <nuxt-img
              v-if="i < 7"
              :src="`/static_files/uploads/${item.id}.jpeg`"
              :alt="`Постер ${item.name}`"
              fit="cover"
              loading="lazy"
              format="webp"
              width="200"
              height="113"
              quality="70"
              preload
            />
            <nuxt-img
              v-else
              :src="`/static_files/uploads/${item.id}.jpeg`"
              :alt="`Постер ${item.name}`"
              fit="cover"
              loading="lazy"
              format="webp"
              width="200"
              height="113"
              quality="70"
            />
          </div>
          <strong>
            {{ item.name }}
          </strong>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: 3498,
          name: `Grand Theft Auto V`,
        },
        {
          id: 41494,
          name: `Cyberpunk 2077`,
        },
        {
          id: 326243,
          name: `Elden Ring`,
        },
        {
          id: 3328,
          name: `The Witcher 3: Wild Hunt`,
        },
        {
          id: 9609,
          name: `Euro Truck Simulator 2`,
        },
        {
          id: 58175,
          name: `God of War`,
        },
        {
          id: 58134,
          name: `Marvel's Spider-Man`,
        },
        {
          id: 622492,
          name: `Forza Horizon 5`,
        },
        {
          id: 324997,
          name: `Baldur's Gate III`,
        },
        {
          id: 28,
          name: `Red Dead Redemption 2`,
        },
        {
          id: 278,
          name: `Horizon Zero Dawn`,
        },
        {
          id: 46889,
          name: `Monster Hunter: World`,
        },
        {
          id: 5679,
          name: `The Elder Scrolls V: Skyrim`,
        },
        {
          id: 718135,
          name: `Palworld`,
        },
        {
          id: 2093,
          name: `No Man's Sky`,
        },
        {
          id: 58777,
          name: `DOOM Eternal`,
        },
        {
          id: 58779,
          name: `Starfield`,
        },
        {
          id: 463723,
          name: `Far Cry 6`,
        },
        {
          id: 795632,
          name: `Resident Evil 4`,
        },
        {
          id: 58755,
          name: `Devil May Cry 5`,
        },
      ],
    }
  },
  methods: {
  },
}
</script>

<style src="~/assets/topgames.styl" lang="stylus" />
